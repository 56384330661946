export enum dataLayerEventKey {
  REGISTRATION = "registration",
  NEWSLETTER = "newsletter",
  LOGIN_ATTEMPT = "login_attempt",
  LOGIN_FAIL = "login_fail",
  SEARCH = "search",
}

export enum dataLayerStatusKey {
  SUBMIT_ATTEMPT = "Submit Attempt",
  SUBMIT_FAIL = "Submit Fail",
}

export enum dataLayerFormName {}

export default function setGADataLayer(obj) {
  if (typeof window === "object") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  }
}
